<template>
    <div>
        <table class="table table-bordered table-hover">
            <thead>
                <tr>
                    <th></th>
                    <th>{{ $t('monitoringLocation.area') }}</th>
                    <th>{{ $t('monitoringLocation.average_duration') }}</th>
                    <th>{{ $t('monitoringLocation.total_duration') }}</th>
                    <th>{{ $t('users_list_params.days') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="place in places">
                    <td class="checkbox-col border-checkbox-section">
                        <div class="border-checkbox-group border-checkbox-group-primary">
                            <input class="border-checkbox" type="checkbox" :id="`place_${place.id}`" :value="place" v-model="selectedPlaces">
                            <label class="form-label border-checkbox-label" :for="`place_${place.id}`"></label>
                        </div>
                    </td>
                    <td>{{ place.place.address }}</td>
                    <td>{{ toHHMMSS(place.average_duration) }}</td>
                    <td>{{ toHHMMSS(place.total_duration) }}</td>
                    <td>{{ place.period }}</td>
                </tr>
            </tbody>
            <tbody>
                <tr v-if="!places.length">
                    <td colspan="5">{{ $t('monitoringLocation.emptyPlacesList') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
    name: 'visitedPlacesEvents',
    props: {
        value: {
            type: Array,
            default: () => []
        },
        places: {
            type: Array,
            required: true
        },       
    },
    data() {
        return {
            selectedPlaces: []
        }
    },
    methods: {
        toHHMMSS(mins) {
            var sec_num = parseInt(mins, 10)*60;
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            return hours+':'+minutes+':'+seconds;
        }
    },
    computed: {
        ...mapGetters('locationMonitoring', ['targets'])
    },
    watch: {
        selectedPlaces(val) {
            this.$emit('input', val)
        }
    },
}
</script>

<style scoped>
/* Add your component styles here */
</style></ul>