<template>
    <div>
        <form @submit.prevent="editArea">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="title">{{ $t('monitoringLocation.titleArea') }} *</label>
                        <input class="form-control" id="title" rows="3" v-model="payload.title">
                    </div>
                    <div class="form-group">
                        <label for="note">{{ $t('monitoringLocation.noteArea') }}</label>
                        <textarea class="form-control" id="note" rows="3" v-model="payload.note"
                            ></textarea>
                    </div>
                    <div class="form-group">
                        <label for="expires_at">{{ $t('monitoringLocation.expires_at') }}</label>
                        <date-picker v-model="payload.expires_at" type="datetime" id="expires_at"
                            :placeholder="$t('settings.datePlaceholder')" format="DD.MM.YYYY HH:mm" :editable="false"
                            :lang="lang[$i18n.locale]" :disabled-date="disabledBeforeToday"></date-picker>
                    </div>
                    <div class="form-group">
                        <label>{{ $t('monitoringLocation.targets') }}</label>
                        <multiselect v-model="targetSelected" class="m-b-20 targets-select" :options="dropdownList"
                            :searchable="false" :multiple="true" :placeholder="$t('monitoringLocation.select_targets')"
                            track-by="target_id" :clear-on-select="false" selectLabel="" deselectLabel="" selectedLabel=""
                            :preserve-search="true">
                            <template slot="maxElements">{{ $t('monitoring.max_targets') }}</template>
                            <template slot="noOptions">{{ $t('monitoring.no_targets') }}</template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">{{ props.option.alias ? props.option.alias :
                                    $t('monitoring.' + props.option.target.param) }}:
                                    {{ props.option.target.value }}</div>
                            </template>
                            <template slot="tag" slot-scope="{option}">
                                <span class="multiselect__tag">
                                    <span>{{ option.alias ? option.alias :
                                        '' }}:
                                        {{ option.target.value }}</span>
                                    <i tabindex="1" class="multiselect__tag-icon" @click="removeResult(option)"></i>
                                </span>
                            </template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <footer class="d-grid gap-2 m-t-15">
                <b-button type="submit"  variant="primary" :disabled="this.$v.$invalid" >{{ $t('ui.Ok') }}
                    <span v-if="isLoading">
                    <b-spinner small></b-spinner>
                    </span>
                </b-button>
                <b-button type="button" variant="secondary"
                            @click="closeDialog">{{ $t('user.cancel') }}
                </b-button>
            </footer>
        </form>
    </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import drawLocales from 'leaflet-draw-locales';
import { parse, stringify } from 'wkt';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';
import { mapGetters } from "vuex";
import moment from 'moment';
import { validationMixin } from 'vuelidate'
import {
  required
} from "vuelidate/lib/validators";
    export default {
        name: 'editArea',
        props: ['area'],
        components: {
            DatePicker,
            Multiselect
        },
        setup() {
            return {
                lang: {
                    ua: {
                        formatLocale: {
                            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
                            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
                            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
                            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                            firstDayOfWeek: 1,
                            firstWeekContainsDate: 7
                        }
                    },
                    ru: {
                        formatLocale: {
                            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
                            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
                            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
                            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
                            firstDayOfWeek: 1,
                            firstWeekContainsDate: 1
                        }
                    },
                    en: {
                        formatLocale: {
                            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                            firstDayOfWeek: 0,
                            firstWeekContainsDate: 1
                        }
                    }
                },
            }
        },
        mixins: [validationMixin],
        data() {
            return {
                payload: {
                    area: null,
                    expires_at: null,
                    title: null,
                    note: null,
                    target_ids: [],
                    alert_status: false,
                    status: true
                },
                map: null,
                center: [50.4501, 30.5234],
                zoom: 5,
                startZoom: 3,
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                areaData: null,
                createAreaModal: false,
                drawnItems: null,
                searchItems: null,
                targetSelected: [],
                isLoading: false
            }
        },
        validations: {
            payload: {
                title: {
                    required
                },
            }
        },
        methods: {
            editArea() {
                this.isLoading = true
                this.$store.dispatch('locationMonitoring/updateArea', this.computedPayload).then(() => {
                    this.isLoading = false
                    this.$emit('closeAreaModal')
                    this.$store.dispatch('locationMonitoring/getAreas')
                })
            },
            initMap() {

            },
            removeResult(tag) {
                const ind = this.targetSelected.findIndex(v => v.target_id === tag.target_id);
                if (ind >= 0) {
                    this.targetSelected.splice(
                        ind,
                        1
                    );
                }
            },
            disabledBeforeToday(date) {
                if(moment(date).isSame(moment(this.area.expires_at, 'X')) ) {
                    return false;
                }
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                return date < today;
            },
            closeDialog() {
                this.$emit('closeAreaModal')
            }
        },
        watch: {
            targetSelected: {
                handler: function (val) {
                    this.payload.target_ids = val.map(item => item.target_id)
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters('monitoring', ['targets']),
            dropdownList() {
                let list = [];
                this.targets.forEach(target => {
                    list.push(target)
                })
                return list;
            },
            computedPayload() {
                const payload = {
                    title: undefined,
                    note: undefined,
                    area: undefined,
                    expires_at: undefined,
                    target_ids: undefined,
                    alert_status: true,
                    status: true
                };
                Object.keys(payload).forEach(key => {
                    if (this.payload[key] !== null) {
                        if (key === 'expires_at') {
                            payload[key] = parseInt(moment(this.payload[key]).endOf('day').format('X'));
                        } else {
                            payload[key] = this.payload[key];
                        }

                    }
                });
                return JSON.parse(JSON.stringify({id: this.area.id, payload}));
            }
        },
        mounted() {
            this.payload.area = this.area.area
            this.payload.expires_at = moment(this.area.expires_at, 'X').toDate()
            this.payload.title = this.area.title
            this.payload.note = this.area.note
            this.payload.target_ids = this.area.targets.map(item => item.target_id)
            this.targetSelected = this.targets.filter(item => this.payload.target_ids.includes(item.target_id))
        },
    }
</script>