<template>
  <div style="display: flex;justify-content: center;">

    <span class="m-r-15" v-b-modal="'updateTaskIntersection'+rowItem.id" v-if="!rowItem.status" @click="setStartSubscription"  :content="$t('monitoringLocation.start_task')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-play f-w-600 f-16 playColor "></i>
    </span>

    <span class="m-r-15" v-if="rowItem.status" @click="stopSubscription" :content="$t('monitoringLocation.stop_task')" v-tippy="{ placement : 'top',  arrow: true }" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-pause f-w-600 f-16 stopColor"></i>
    </span>

    <button class="action-btn-target" v-b-modal="'updateTaskIntersection'+rowItem.id"  @click="setEditSubscription" style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoringLocation.edit_task')" v-tippy="{ placement : 'top',  arrow: true }"></i>
    </button>



    <b-modal class="createTaskDialog" size="lg" v-model="visible" :id="'updateTaskIntersection'+rowItem.id" centered hide-footer :title="this.$t('monitoringLocation.intersection_task_edit_modal_title')">
      <div class="intersectionsTaskCreate">
        <div v-if="isLoading" class="skeletonContainer">
          <b-skeleton width="80%" :animated="true"></b-skeleton>
          <b-skeleton width="40%" :animated="true"></b-skeleton>
          <b-skeleton width="20%" :animated="true"></b-skeleton>
          <b-skeleton :animated="true"></b-skeleton>
        </div>
        <div class="row" v-if="!isLoading">


          <div class="contentBlock col-md-6">
            <div class="scroll-container targetList">

              <b-table
                  v-if="targets.length"
                  :items="filteredTargets"
                  :fields="fields"
                  :busy="isLoading"
                  small
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(alias)="row">
                  <div class="border-checkbox-section">
                    <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                      <input class="border-checkbox"
                             type="checkbox"
                             :id="`taskUpdate`+row.value"
                             :disabled="true"
                             :value="row.item.id"
                             v-model="selectedTargets">
                      <label class="border-checkbox-label d-flex" :for="`taskUpdate`+row.value">
                        <span v-if="row.value.length <= 25">{{row.value}}</span>
                        <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                        <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                      </label>
                    </div>
                  </div>
                </template>

                <template #cell(2)="row">
                  <div style="display: flex">
                    <div style="display: flex;justify-content: flex-start; align-items: center">
                      <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                      <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                    </div>
                    <div style="display: flex;justify-content: flex-start; align-items: center" class="m-l-15 iconContainer">
                      <i class="feather icon-info f-16" v-if="row.item.target.locations.length"  :content="$t('monitoringLocation.info_tooltip_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                      <i class="feather icon-info crossed  f-16" v-else :content="$t('monitoringLocation.info_tooltip_not_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
          <div class="contentBlock col-md-6">
            <div class="settingContainer">
              <div style="height: 22px">
                <span style="font-weight: 600; margin-left: 12px">Налаштування</span>
              </div>
              <div class="settingsList" style="margin-top: 10px">
                <div class="settingsBlock">
                  <label for="range-1" style="width: 100%">
                    <div style="display: flex; justify-content: space-between; margin-bottom: 8px">
                      <span> {{$t('monitoringLocation.distance')}}:</span>
                      <span> {{ distance }} м</span>
                    </div>
                  </label>
                  <b-form-input :disabled="selectedTargets.length !== 2" style="width: 100%" id="range-1" v-model="distance" type="range" min="300" max="1000"></b-form-input>
                </div>

                <div class="settingsBlock" v-if="datePikerState">
                  <div style="display: flex; justify-content: start;margin-bottom: 8px">
                    <span> Дата завершення спостереження:</span>
                  </div>
                  <date-picker v-model="taskEndDate"
                               type="datetime"
                               :placeholder="$t('settings.datePlaceholder')"
                               valueType="YYYY-MM-DD HH:mm"
                               format="DD.MM.YYYY HH:mm"
                               :editable="false"
                               :lang="lang[$i18n.locale]"
                               :disabled-date="disabledRange"
                               :disabled="selectedTargets.length !== 2"></date-picker>
                </div>

              </div>
            </div>
          </div>
          <b-form-group>
            <div style="height: 22px">
              <span style="font-weight: 600; margin-left: 12px">{{this.$t('monitoring.note')}}</span>
            </div>
            <b-form-textarea class="textAreaBlock m-t-15" id="note" rows="3" v-model="note"
            ></b-form-textarea>
          </b-form-group>
          <footer class="d-grid gap-2 m-t-15">
            <b-button variant="primary" class="btn" @click="startSubscription">{{ $t('ui.Ok') }}
              <span v-if="isLoading">
                <b-spinner small></b-spinner>
              </span>
            </b-button>
            <b-button type="button" variant="secondary"
                      class="btn">{{ $t('user.cancel') }}
            </b-button>
          </footer>
        </div>
      </div>
    </b-modal>

  </div>





</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";


export default {
  name: "intersectionTaskUpdateModal",
  components: { DatePicker },
  props: ['rowItem'],
  data(){
    return {
      visible :false,
      isLoading: false,
      distance: 300,
      selectedTargets: [],
      modalType: '',
      // enableTask: false,
      taskEndDate: '',
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      },
      note:'',
      disabledTargetsIds: [],
    }
  },
  mounted() {
    this.selectedTaskTargets();
  },
  computed:{
    ...mapGetters('monitoring', ['targets']),
    filteredTargets() {
      let arr = []
      if (this.targets.length && this.selectedTargets) {
        this.targets.forEach(target => {
          if (target.target.param === 'isdn') {
            let obj = target
            obj.canBeSelected = this.disabledTargetsIds.includes(target.id);
            arr.push(obj)
          }
        })
        return arr
      }
    },
    fields(){
      return  [
        {
          key: 'alias',
          label:  this.$t('monitoring.table_name'),
          sortable: false
        },
        {
          key: '2',
          label:  this.$t('monitoringLocation.active_status'),
          sortable: false
        },
      ]
    },
    // buttonState(){
    //   if (this.rowItem.status){
    //     return true
    //   } else {
    //     if (this.modalType === 'start'){
    //       return this.taskEndDate !== ''
    //     } else if (this.modalType === 'edit'){
    //       return true
    //     }
    //   }
    // },
    datePikerState(){
      if (this.modalType === 'start'){
        return true
      } else if (this.modalType === 'edit') {
        return !!this.rowItem.status;
      }
    },
  },
  methods: {
    selectedTaskTargets(){
      this.rowItem.targets.forEach(target => {
        this.selectedTargets.push(target.subscription_id)
      })
      if (this.rowItem.note){
        this.note = this.rowItem.note
      }
      this.distance = this.rowItem.distance
    },
    disabledRange(date) {
      return  moment().toDate() > date;
    },
    startSubscription(){
      let payload = {
        task_id: this.rowItem.id,
        target_ids: this.selectedTargets,
        distance: this.distance,
        expires_at:  parseInt(moment(this.taskEndDate).format("X")),
        note: this.note,
        status:  this.modalType === 'start'
      }
      if (payload.expires_at === '' ||  !payload.expires_at){
        delete payload.expires_at
      }
      if (this.note === ''){
        delete payload.note
      }

      this.isLoading = true;
      this.$store.dispatch('locationMonitoring/updateCrossTrackingTask', payload).then(()=>{
        this.$emit('getAreaTrackingTask');
        this.isLoading = false;
      })
    },
    stopSubscription(){
      let payload = {
        task_id: this.rowItem.id,
        // target_ids: this.selectedTargets,
        status: false
      }
      this.isLoading = true;
      this.$store.dispatch('locationMonitoring/updateCrossTrackingTask', payload).then(()=>{
        this.$emit('getAreaTrackingTask');
        this.isLoading = false;
      })

    },
    setStartSubscription(){
      this.modalType = 'start'
    },
    setEditSubscription(){
      this.modalType = 'edit'
    },
  },
}
</script>

<style lang="scss">
.intersectionsTaskCreate {
  .hideText{
    width: 6vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .textAreaBlock{
    //margin-right: 16px;
    //margin-left: 16px;
    //width: calc(100% - 32px);
    height: 100px;
    //margin-bottom: 12px;
  }
  .scroll-container {
    max-height: 340px ;
    overflow-y: scroll;
    padding-bottom: 16px;
  }
  .settingContainer{
    margin-top: 16px;
  }
}
</style>