<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-lg-5">
        <div class="card m-b-0">
          <div class="card-body">
            <clusterTasks v-model="selectedTargets" />
            <div  class="paceHolder" style="height: 58px"></div>

            <div class="card m-b-0">
              <div class="block rangeSelectBlock">
                <div class="rangeSelectBlockTitle"><h4>{{ $t('monitoringLocation.select_perion_cluster') }}</h4></div>
                <date-range-picker
                    ref="picker"
                    :ranges="calendarTranslate.ranges[$i18n.locale]"
                    v-model="dateRange"
                    :opens="'right'"
                    :locale-data="calendarTranslate.calendar[$i18n.locale]"
                >
                  <template v-slot:input="picker">
                    <i class="feather icon-calendar m-r-5"></i>
                    {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
                  </template>
                  <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end">
                    <div class="d-flex align-items-center">
                      <b >{{ data.rangeText }}</b>
                    </div>
                    <div class="d-grid p-10 d-lg-flex ">
                      <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
                      <button @click="data.clickCancel" class="btn btn-secondary m-l-10">{{ $t('billing.cancelLabel') }}</button>
                    </div>
                  </div>
                </date-range-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-7">
        <mapPlaces :selectedPlaces="selectedPlaces" ref="mapPlaces"></mapPlaces>
      </div>
      <div class="col-md-12 m-t-15">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="isLoadingPlaces">
              <visitedPlacesEvents v-model="selectedPlaces" :places="places"  />
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapPlaces from './mapPlaces.vue'
import visitedPlacesEvents from '@/components/monitoringLocation/visitedPlacesEvents.vue'
import {mapGetters} from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import "@/components/billing/pickerstyle.css"
import calendarTranslate from "@/configs/calendarTranslate";
import clusterTasks from '@/components/monitoringLocation/clusterTasks.vue'
import moment from 'moment'
export default {
  name: "visitPlaces",
  components: {
    mapPlaces,
    visitedPlacesEvents,
    DateRangePicker,
    clusterTasks
  },
  data(){
    return {
      taskType: 'visit',
      selectedTargets: [],
      isLoadingPlaces: false,
      selectedPlaces: [],
      dateRange: {
        startDate: moment().subtract(6, 'days').toDate(),
        endDate:   moment().toDate(),
      },
      dateChange: false,
    }
  },
  setup() {
    return {
      moment,
      calendarTranslate
    }
  },
  computed: {
    ...mapGetters('locationMonitoring', ['places']),
    computedPayload() {
      return {
        filters: {
          target_id: this.selectedTargets,
          created_from: parseInt(moment(this.dateRange.startDate).startOf('day').format('X')),
          created_to: parseInt(moment(this.dateRange.endDate).endOf('day').format('X'))
        }
      }
    }
  },
  methods: {
    targetsListChange(targets){
      this.selectedTargets = targets
    },
    getVisitPlaces(){
      this.isLoadingPlaces = true;
      this.$store.dispatch('locationMonitoring/getVisitedPlaces', this.computedPayload).then(() => {
        this.dateChange = false;
        this.isLoadingPlaces = false;
      })
    }
  },
  watch: {
    selectedTargets: {
      handler(val){
        if(val.length){
          this.getVisitPlaces();
        } else {
          this.$store.dispatch('locationMonitoring/setEmptyPlaces')
        }
      }
    },
    dateRange: {
      handler: function () {
        if(!this.dateChange){
          this.dateChange = true;
          if(this.selectedTargets.length){
            this.getVisitPlaces()
          }
        }
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.tippy-popper{
  word-break: break-all;
}
</style>
<style lang="scss">

</style>