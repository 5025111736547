<template>
    <div class="cluster-tasks">
        <div v-if="isLoading" class="skeletonContainer">
            <div class="scroll-container">
                <b-skeleton width="80%" :animated="true"></b-skeleton>
                <b-skeleton width="40%" :animated="true"></b-skeleton>
                <b-skeleton width="20%" :animated="true"></b-skeleton>
                <b-skeleton width="80%" :animated="true"></b-skeleton>
                <b-skeleton width="60%" :animated="true"></b-skeleton>
                <b-skeleton width="40%" :animated="true"></b-skeleton>
                <b-skeleton :animated="true"></b-skeleton>
            </div>
        </div>
        <table v-else class="table b-table table-sm">
            <thead>
                <tr>
                    <th>{{ $t('monitoringLocation.target') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="target in targets">
                    <td class="border-checkbox-section">
                        <div class="border-checkbox-group border-checkbox-group-primary">
                            <input class="form-label border-checkbox" v-model="selectedTargets"
                                type="checkbox" :id="`target_cluster_${target.target_id}`" :value="target.target_id">
                            <label class="border-checkbox-label" 
                                :for="`target_cluster_${target.target_id}`">{{ target.alias }}</label>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'ClusterTasks',
    data() {
        return {
            isLoading: false,
            isError: false,
            errorText: '',
            selectedTargets: this.value,
        };
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        getTargets() {
            let payload = {
                    filters: {
                    type: 'location'
                }
            }
            this.isLoading = true
            this.$store.dispatch('monitoring/getTargets', payload).then(() => {
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false;
                this.isError = true;
                this.errorText = err.response.data.message ? err.response.data.message : err.response.data.error
            })
        },
        
    },
    computed: {
        ...mapGetters('monitoring', ['targets']),

    },
    watch: {
        value() {
            this.selectedTargets = this.value
        },
        selectedTargets() {
            this.$emit('input', this.selectedTargets)
        }
    },
    mounted() {
        // Add your mounted lifecycle hook code here
    }
};
</script>

<style scoped>
/* Add your component styles here */
</style>