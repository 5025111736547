<template>
  <div class="container-fluid">

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between border-checkbox-section m-b-10">
              <div class="border-checkbox-group border-checkbox-group-primary">
                <input class="border-checkbox" v-model="hideInactiveAreas" type="checkbox" id="hideInactiveAreas">
                <label class="form-label border-checkbox-label" for="hideInactiveAreas">{{ hideInactiveAreas 
                  ? $t('monitoringLocation.showInactiveTask') : $t('monitoringLocation.hideInactiveTask') }}</label>
              </div>
              <button class="btn btn-primary lower-button" @click="createAreaModal = true" @close="() => createAreaModal = false">{{ $t('monitoringLocation.create_task') }}</button>
            </div>
            <b-overlay :show="isLoading">
              <zonesList v-model="selectedZones" :zones="filteredAreas" @update="getAreas" :value="selectedZones" />
            </b-overlay>
            <pagination :data="areaLogsPagination" @paginate="offset =+ limit" :isPaginate="isLoadingLogs"></pagination>
            <div class="card m-b-0">
              <div class="block rangeSelectBlock">
                <div class="rangeSelectBlockTitle"><h4>{{ $t('monitoringLocation.select_period_zone') }}</h4></div>
                <date-range-picker
                    ref="picker"
                    :ranges="calendarTranslate.ranges[$i18n.locale]"
                    v-model="dateRange"
                    :opens="'right'"
                    :locale-data="calendarTranslate.calendar[$i18n.locale]"
                >
                  <template v-slot:input="picker">
                    <i class="feather icon-calendar m-r-5"></i>
                    {{ moment(picker.startDate).format('DD.MM.YYYY') }} - {{ moment(picker.endDate).format('DD.MM.YYYY') }}
                  </template>
                  <div slot="footer" slot-scope="data" class="slot d-lg-flex justify-content-xl-end">
                    <div class="d-flex align-items-center">
                      <b >{{ data.rangeText }}</b>
                    </div>
                    <div class="d-grid p-10 d-lg-flex ">
                      <b-button @click="data.clickApply" variant="primary" v-if="!data.in_selection" class="acceptButton btn">{{ $t('billing.applyLabel') }}</b-button>
                      <button @click="data.clickCancel" class="btn btn-secondary m-l-10">{{ $t('billing.cancelLabel') }}</button>
                    </div>
                  </div>
                </date-range-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <mapZones :selectedZones="selectedZones" ref="mapZones"></mapZones>
      </div>
      <div class="col-md-12 m-t-15">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="isLoadingLogs">
              <table class="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>{{ $t('monitoringLocation.area') }}</th>
                    <th>{{ $t('monitoringLocation.target') }}</th>
                    <th>{{ $t('monitoringLocation.period_being') }}</th>
                  </tr>
                </thead>
                <tbody v-if="areaLogs.length">
                  <tr v-for="areaLog in areaLogs" >
                    <td>{{ areasObj[areaLog.task_id].title }} <span v-if="selectedZones.includes(areaLog.task_id)" class="badge-selected-zone" :class="[colorZone(areaLog.task_id)]"></span></td>
                    <td>{{ targets[areaLog.location.target_id].alias }} <a href="#" @click.prevent="setPoint(areaLog.location.location)"><i class="feather icon-crosshair"></i></a></td>
                    <td>{{ beingPeriod(areaLog.location.period_from, areaLog.location.period_to) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3">{{ $t('monitoringLocation.emptyListZoneEvents') }}</td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </div>
      </div>
      <b-modal size="xl" v-model="createAreaModal" id="add-area" centered hide-footer :title="$t('monitoringLocation.add_area')">
        <createArea ref="createArea" ></createArea>
      </b-modal>
    </div>
  </div>
</template>

<script>
import zonesList from './zonesList.vue';
import mapZones from './mapZones.vue';
import { mapGetters } from 'vuex';
import createArea from './createArea.vue';
import moment from 'moment';
import zoneFilters from './zoneFilters.vue';
import _ from 'underscore';
import DateRangePicker from 'vue2-daterange-picker'
import "@/components/billing/pickerstyle.css"
import calendarTranslate from "@/configs/calendarTranslate";
import { isUndefined } from 'underscore';
import pagination from './pagination.vue';

export default {
  name: "alarmZones",
  data() {
    return {
      zones: [],
      hideInactiveAreas: false,
      selectedZones: [],
      isLoading: true,
      createAreaModal: false,
      isLoadingLogs: false,
      dateRange: {
        startDate: moment().subtract(6, 'days').toDate(),
        endDate:   moment().toDate(),
      },
      dateChange: false,
      limit: 200,
      offset: 0,
      filteredAreas: []
    }
  },
  setup() {
    return {
      moment,
      calendarTranslate
    }
  },
  components: {
    zonesList,
    mapZones,
    createArea,
    zoneFilters,
    DateRangePicker,
    pagination
  },
  computed: {
    ...mapGetters('locationMonitoring', ['areas', 'areaLogs', 'domain', 'areaLogsPagination']),
    payload() {
      return {
        filters: {
          task_id: this.selectedZones,
          created_from: parseInt(moment(this.dateRange.startDate).startOf('day').format('X')),
          created_to: parseInt(moment(this.dateRange.endDate).endOf('day').format('X'))
        },
        order_by: 'created_at',
        order_direction: 'desc',
        offset: this.offset,
        limit: this.limit,
      }
    },
    targets() {
      return _.indexBy(_.flatten(this.areas.map(item => item.targets)), 'target_id')
    },
    areasObj() {
      return _.indexBy(this.areas, 'id')
    },

  },
  methods:{
    getAreas() {
      this.isLoading = true
      this.$store.dispatch('locationMonitoring/getAreas').then(() => {
        this.isLoading = false
        this.filteredAreas = this.areas
      })
    },
    getAreaTrackingLogs() {
      this.isLoadingLogs = true
      this.$store.dispatch('locationMonitoring/areaTrackingLogs', this.payload)
        .then(() => {
          this.dateChange = false;
          this.isLoadingLogs = false
          localStorage.setItem('lastWatchedAreasLogsTime', moment().format('X'))
        })
    },
    clearFilters() {
      this.isLoadingLogs = true;
      setTimeout(() => {
        this.isLoadingLogs = false;
        this.$store.commit('locationMonitoring/setAreasLogs', {data:[], total: 0, offset: 0})
      }, 1000)
    },
    setPoint(location) {
      window.scrollTo(0, 0);
      this.$refs.mapZones.setMarker(location)
    },
    beingPeriod(period_from, period_to){
      const loc = this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale;
      
      moment.locale(loc);
      let sameDay = moment(period_from).isSame(period_to, 'day');
      let sameTime = period_from === period_to
      let text = moment(period_from, 'X').format('DD MMMM YYYY') +', '+ moment(period_from, 'X').format('HH:mm');
      if (sameDay) {
        if(!sameTime) {
          text += ' - ' + moment(period_to, 'X').format('HH:mm');
        }
      } else {
        text += ' - ' + moment(period_to, 'X').format('DD MMMM YYYY HH:mm');
      }
      return text
    },
    colorZone(id) {
      const index = this.selectedZones.indexOf(id)
      return `color-${index}`
    },
    preventFilter() {
      const notification = this.$store.getters['locationMonitoring/notificationMonitoring']
      if(notification && notification.type === 'area') {
        this.selectedZones = notification.taskId ? [notification.taskId] : undefined
        const time = localStorage.getItem('lastWatchedAreasLogsTime')
        const startDate = time ? moment(time, 'X').toDate() : moment().subtract(6, 'days').toDate()
        this.dateRange = {
          startDate,
          endDate: moment().toDate(),
        }
        console.log('notification', notification)
        this.getAreaTrackingLogs()
      }

    }
  },
  watch: {
    createAreaModal(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.createArea.updateMap()
        }, 100)
      }
    },
    selectedZones(val) {
      this.offset = 0;
      if(isUndefined(val)) {
        return;
      }
      if(val.length > 0) {
        this.getAreaTrackingLogs()
      } else {
        this.clearFilters()
      }
    },
    dateRange: {
      handler: function () {
        if(!this.dateChange){
          this.dateChange = true;
          this.offset = 0;
          if(!_.isUndefined(this.selectedZones) && this.selectedZones.length){
            this.getAreaTrackingLogs()
          } else if(_.isUndefined(this.selectedZones)){
            this.getAreaTrackingLogs() 
          }
        }
      },
      deep: true
    },
    offset(val) {
      if(!_.isUndefined(this.selectedZones) && this.selectedZones.length){
        this.getAreaTrackingLogs()
      } else if(_.isUndefined(this.selectedZones)){
        this.getAreaTrackingLogs() 
      }
    },
    hideInactiveAreas(val) {
      this.filteredAreas = this.areas.filter(area => {
        return val ? area.status === true : true
      })
    }
  },
  async mounted() {
    await this.getAreas()
    await this.$store.commit('locationMonitoring/setAreasLogs', {data:[], total: 0, offset: 0})
    await this.preventFilter()
  }
}
</script>

<style>
.areas-list-card {
  height: 368px;
  overflow-y: auto;
}

.color-0 {
    background-color: rgba(0, 123, 255, 0.5);
}

.color-1 {
    background-color: rgba(40, 167, 69, 0.5);
}

.color-2 {
    background-color: rgba(23, 162, 184, 0.5);
}

.color-3 {
    background-color: rgba(220, 53, 69, 0.5);
}

.color-4 {
    background-color: rgba(55, 71, 79, 0.5);
}

</style>