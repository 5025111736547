<template>
  <div class="tabContainer monitoringLocationTargets">
    <div class="container-fluid">
      <div class="row">
        <div class="contentBlock">
          <div v-if="isLoading" class="skeletonContainer">
            <b-skeleton width="80%" :animated="true"></b-skeleton>
            <b-skeleton width="40%" :animated="true"></b-skeleton>
            <b-skeleton width="20%" :animated="true"></b-skeleton>
            <b-skeleton :animated="true"></b-skeleton>
          </div>
        </div>
        <div class="contentBlock" v-if="!isLoading">
          <div class="d-flex m-b-15" style="justify-content: end">
            <add-target
                class="m-r-10"
                targetType="location"
            />

          </div>
          <div class="card" style=" padding: 8px 14px;">
            <b-table
                sticky-header
                v-if="targets.length"
                :items="targets"
                :fields="fields"
                :busy="isLoading"
                small
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>


              <template #cell(alias)="row">
                <span v-if="row.value.length <= 20">{{row.value}}</span>
                <span v-else :class="{'hideText': row.value.length > 20}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
<!--                {{row.value}}-->
              </template>

              <template #cell(note)="row">
                  <i class="feather icon-file-text f-16" v-if="row.value" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.value" style="color:#bebebe; word-break: break-word" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </template>

              <template #cell(1)="row">
                <div style="display: flex">
                  <div style="display: flex;justify-content: flex-start; align-items: center">
                    <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                    <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  </div>
                  <div style="display: flex;justify-content: flex-start; align-items: center" class="m-l-15 iconContainer">
                    <i class="feather icon-info f-16" v-if="row.item.target.locations.length"  :content="$t('monitoringLocation.info_tooltip_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                    <i class="feather icon-info crossed  f-16" v-else :content="$t('monitoringLocation.info_tooltip_not_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                  </div>
                </div>
              </template>

              <template #cell(2)="row">
                <div v-if="row.item.active">
                  {{moment(row.item.active_task.started_at, 'X').format('DD.MM.YYYY, HH:mm')}}
                </div>
              </template>

              <template #cell(3)="row">
                <div v-if="row.item.active">
                  {{moment(row.item.active_task.expires_at, 'X').format('DD.MM.YYYY, HH:mm')}}
                </div>
              </template>


              <template #cell(target.patam)="row">
                {{row.value}}
              </template>

              <template #cell(target.value)="row">
                {{row.value}}
              </template>


              <template #cell(4)="row">
                <location-target-action
                  :target="row.item"
                  @getTargets="getTargets"
                />
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import LocationTargetAction from "@/components/monitoringLocation/locationTargetAction";
import AddTarget from "@/components/monitoring/addTarget";

export default {
  name: "MonitoringLocationTargets",
  components: {AddTarget, LocationTargetAction},
  setup() {
    return {
      moment
    }
  },
  data(){
    return {
      isEdit: true,
      isLoading: false,
      selectedTargets: []
    }
  },
  computed: {
    ...mapGetters('monitoring', ['targets']),
    fields(){
      return  [
        {
          key: 'alias',
          label:  this.$t('monitoringLocation.name'),
          sortable: false
        },

        {
          key: 'target.param',
          label: this.$t('monitoringLocation.param'),
          sortable: false
        },
        {
          key: 'target.value',
          label: this.$t('monitoringLocation.value'),
          sortable: false
        },
        {
          key: 'note',
          label: this.$t('monitoringLocation.noteArea'),
          sortable: false
        },
        {
          key: '2',
          label:  this.$t('monitoringLocation.start_time'),
          sortable: false
        },
        {
          key: '3',
          label:  this.$t('monitoringLocation.end_time'),
          sortable: false
        },
        {
          key: '1',
          label:  this.$t('monitoringLocation.status'),
          sortable: false
        },
        {
          key: '4',
          label: this.$t('monitoring.table_action'),
          sortable: false,
          class: 'actionsBlock'
        },



      ]
    },
  },
  methods:{
    getTargets() {
      let payload = {
        filters: {
          type: 'location'
        }
      }
      this.isLoading = true
      this.$store.dispatch('monitoring/getTargets', payload).then(() => {
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false;
        this.isError = true;
        this.errorText = err.response.data.message ? err.response.data.message : err.response.data.error
      })
    },
  },
  mounted() {
    this.getTargets()
  }
}
</script>

<style lang="scss">
.monitoringLocationTargets{
  .b-table-sticky-header{
    max-height: 80vh;
  }
}
.hideText {
  width: 10vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tippy-popper{
  word-break: break-all;
}

.actionsBlock {
  width: 80px;
  text-align: center;
}
</style>